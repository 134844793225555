<template>
  <div class="content">
    <div>
      <el-input v-model="degree" placeholder="请输入温度"></el-input>
      <el-button type="primary" style="margin-top:20px" @click="addDegree"
        >添加</el-button
      >
    </div>
    <div style="background:#0E1325;margin-top:20px">
      <div id="degree" style="width:100%;height:12rem"></div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts"

export default {
  data() {
    return {
      degree: "",
      degreeArray: [],
      dateArray: [],
    }
  },
  mounted() {
    // console.log(this.getDay(0))

    // var weekArray = ["周一", "周二", "周三", "周四", "周五"]
    // 存：localStorage.setItem('weekDay',JSON.stringify(weekArray));
    // 取： weekArray = JSON.parse(localStorage.getItem('weekDay'));
    // 36.32
    if (this.$route.query.username) {
      this.optionPeixun()
    } else {
      this.$router.go(-1)
    }
  },

  methods: {
    addDegree() {
      if (
        JSON.parse(localStorage.getItem("label")) &&
        JSON.parse(localStorage.getItem("label")).length > 0
      ) {
        this.dateArray = JSON.parse(localStorage.getItem("label"))
        this.dateArray.push(this.getDay(0))
        localStorage.setItem("label", JSON.stringify(this.dateArray))
      } else {
        this.dateArray.push(this.getDay(0))
        localStorage.setItem("label", JSON.stringify(this.dateArray))
      }
      if (
        JSON.parse(localStorage.getItem("value")) &&
        JSON.parse(localStorage.getItem("value")).length > 0
      ) {
        this.degreeArray = JSON.parse(localStorage.getItem("value"))
        this.degreeArray.push(this.degree)
        localStorage.setItem("value", JSON.stringify(this.degreeArray))
      } else {
        this.degreeArray.push(this.degree)
        localStorage.setItem("value", JSON.stringify(this.degreeArray))
      }
      this.optionPeixun()
    },
    optionPeixun() {
      var chartDom = document.getElementById("degree")
      var myChart = echarts.init(chartDom)

      let label = JSON.parse(localStorage.getItem("label"))
      let value = JSON.parse(localStorage.getItem("value"))

      var option = {
        grid: {
          top: "10%",
          left: "3%",
          right: "3%",
          bottom: "2%",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            lineStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(255,255,255,0)", // 0% 处的颜色
                  },
                  {
                    offset: 0.5,
                    color: "rgba(255,255,255,1)", // 100% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(255,255,255,0)", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
          },
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            axisLabel: {
              formatter: "{value}",
              fontSize: 14,
              textStyle: {
                color: "#7ec7ff",
              },
            },
            axisLine: {
              lineStyle: {
                color: "#243753",
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#243753",
              },
            },
            axisTick: {
              show: false,
            },
            data: label,
          },
        ],
        yAxis: [
          {
            boundaryGap: false,
            type: "value",
            axisLabel: {
              textStyle: {
                color: "#7ec7ff",
              },
            },
            nameTextStyle: {
              color: "#fff",
              fontSize: 12,
              lineHeight: 40,
            },
            splitLine: {
              lineStyle: {
                color: "#243753",
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#283352",
              },
            },
            axisTick: {
              show: false,
            },
          },
        ],
        series: [
          {
            name: "人数",
            type: "line",
            smooth: true,
            showSymbol: true,
            symbolSize: 8,
            zlevel: 3,
            itemStyle: {
              color: "#19a3df",
              borderColor: "#a3c8d8",
            },
            lineStyle: {
              normal: {
                width: 2,
                color: "#19a3df",
              },
            },
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: "rgba(88,255,255,0.2)",
                    },
                    {
                      offset: 0.8,
                      color: "rgba(88,255,255,0)",
                    },
                  ],
                  false
                ),
              },
            },
            data: value,
          },
        ],
      }
      myChart.setOption(option)
      window.addEventListener("resize", function() {
        myChart.resize() //下面可以接多个图
      })
    },
    getDay(day) {
      var today = new Date()
      var targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day
      today.setTime(targetday_milliseconds) //注意，这行是关键代码

      // var tYear = today.getFullYear()
      var tMonth = today.getMonth()
      var tDate = today.getDate()
      tMonth = this.doHandleMonth(tMonth + 1)
      tDate = this.doHandleMonth(tDate)
      // return tYear + "-" + tMonth + "-" + tDate
      return tMonth + "-" + tDate
    },
    doHandleMonth(month) {
      var m = month
      if (month.toString().length == 1) {
        m = "0" + month
      }
      return m
    },
  },
}
</script>

<style>
.content {
  background: #fff;
  width: 100%;
  padding: 10px;
  height: 100%;
}
</style>
